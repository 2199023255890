import { Container, Flex, Heading, Text } from "@radix-ui/themes";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { t } from "i18next";

const Statement = () => {
  return (
    <div
      id="Statement"
      style={{
        backgroundColor: "var(--accent-9)",
      }}
    >
      <Container size="2">
        <Flex
          direction="column"
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          gap={GAP.sm}
        >
          <Heading
            align="center"
            size={FONT_SIZE.title}
            style={{ color: "var(--yellow-a9)" }}
          >
            {t("statement-header")}
          </Heading>

          <Heading
            align="center"
            weight="light"
            size={FONT_SIZE.subheader}
            style={{ color: "white" }}
          >
            {t("statement-subheader")}
          </Heading>

          <Text size={FONT_SIZE.text} style={{ color: "white" }}>
            <div style={{ textAlign: "center" }}>{t("statement-blurb")}</div>
          </Text>
        </Flex>
      </Container>
    </div>
  );
};

export default Statement;

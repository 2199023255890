import { Container, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { t } from "i18next";

const Technology = () => {
  const ITEMS = ["tech-bullet-1", "tech-bullet-2", "tech-bullet-3"];

  return (
    <Container id="Technology" size="3">
      <Grid
        pt={PAD_LG.pv}
        pb={PAD_LG.pv}
        pl={PAD_LG.ph}
        pr={PAD_LG.ph}
        columns={{ initial: "1", md: "2" }}
        gap={GAP.md}
      >
        <Flex justify="center">
          <img
            alt="Technology"
            className="graphic-lg"
            src="/images/technology/collage.png"
          />
        </Flex>
        <Flex direction="column" gap={GAP.sm}>
          <Heading weight="light" color="indigo" size={FONT_SIZE.header}>
            {t("tech-header")}
          </Heading>

          <ul
            style={{
              paddingInlineStart: "var(--space-5)",
              listStyleImage: "url('/images/check.svg')",
            }}
          >
            {ITEMS.map((m, i) => (
              <li
                key={`technology-${i}`}
                style={{ marginBottom: "var(--space-3)" }}
              >
                <Text size={FONT_SIZE.text}>{t(m)}</Text>
              </li>
            ))}
          </ul>
        </Flex>
      </Grid>
    </Container>
  );
};

export default Technology;

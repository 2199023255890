import { Container } from "@radix-ui/themes";
import { AppRoute } from "enums/router";
import { EMAILS } from "enums/urls";
import Markdown from "react-markdown";

const CONTENT = `## 1. Introduction

The following terms and conditions (the "Terms of Use") are the Terms of Use for our website: www.bridgeme.ca (the "Site") made available by BridgeMe Securities Inc. ("BridgeMe", "we", "us" or "our").

The contents of the Site include, without limitation, all information, data, products, materials, services, software applications and tools, design elements, text, images, photographs, illustrations, audio and video contents, artwork, graphics contained therein or otherwise made available to you in connection therewith (collectively the "Contents") and, unless the context clearly requires otherwise, or we explicitly say so in writing, the term "Site" includes all of the Contents.

These Terms of Use constitute a legally binding agreement between you and BridgeMe regarding your use of and access to the Site. You acknowledge and agree that BridgeMe may, in its sole discretion, change, improve and/or correct any functionality or Contents of the Site and suspend and/or deny access to the Site for any reason, without any obligation and without any notice.

These Terms of Use do not alter in any way the terms and conditions of any other agreement you may have with BridgeMe, unless otherwise agreed to in writing by BridgeMe. If you breach any of these Terms of Use, your authorization to use the Site automatically terminates and you must immediately destroy any Contents in your possession and discontinue all use of the Site.

By using this Site, you represent and warrant that you are at least the age of majority in your province or territory of residence, or in any event, at least eighteen (18) years of age.

## 2. Accepting the Terms of Use and Additional Terms

By accessing and using the Site in any manner, you acknowledge that you have read these Terms of Use and all of the terms and conditions contained herein.  Before continuing to use the Site, please read these Terms of Use and contact us if you have any questions.

BridgeMe reserves the right to amend, modify and supplement these Terms of Use from time to time as it sees fit with additional terms and conditions that govern certain information, content, products and services made available to you via the Site ("Additional Terms").  By accessing and using the Site, you accept and agree to comply with and be bound by such Additional Terms.  Please review these Terms of Use from time to time to ensure that you are aware of and understand any Additional Terms.

The Additional Terms and the [Privacy Policy](/${AppRoute.Privacy}) (located on the Site) are hereby incorporated by reference into these Terms of Use and form part of the legally binding agreement between you and us. To the extent that there is a conflict between these Terms of Use and any Additional Terms, the Additional Terms shall govern. These Terms of Use will remain in full force and effect as long as you are a user of the Site and, in the event of termination of any product, service or feature, you will still be bound by your obligations under these Terms of Use, the [Privacy Policy](/${AppRoute.Privacy}), and any Additional Terms.

## 3. Investment Opportunities

BridgeMe is registered as an Exempt Market Dealer in the provinces of British Columbia, Alberta, Manitoba, Saskatchewan, and Ontario pursuant to National Instrument 31-103, _Registration Requirement, Exemptions and Ongoing Registrant Obligations_ of the Canadian Securities Administrators. Some products and services described in the Site may not be available in all jurisdictions or to all users. The information provided on the Site is not provided to, and may not be used by, any person or entity in any jurisdiction where the provision or use thereof would be contrary to applicable laws, rules or regulations of any governmental authority or where BridgeMe is not authorized to provide such information or services.

In particular, this Site does not provide or offer investor services outside of Canada. Nothing contained in this Site is intended to nor does it constitute an offer or solicitation to purchase securities to any person in the United States or to any U.S. person as such term is defined under the Securities Act of 1933, as amended.

This Site may not be considered an offer or a solicitation to purchase or sell any particular fund or security. Investment opportunities are only available to pre-qualified individuals or institutions and are offered via an Offering Memorandum or other applicable exemption from prospectus requirements under Canadian securities laws.

This Site does not take into account the particular investment objectives or financial circumstances of any specific person who may view it and the information contained in the Site has been prepared by without reference to any particular user's investment requirements or financial situation. The contents of this Site do not constitute financial, legal, or tax advice. Before making an investment, prospective investors are solely responsible and are advised to thoroughly and carefully review each issuer's offering documents with their financial, legal and tax advisors to determine whether an investment is suitable for them. A suitability assessment will be conducted on an individual before an instruction to purchase a security is accepted.

Any comments or complaints regarding the Dealing Representatives of BridgeMe should be made in writing to the Chief Compliance Officer of BridgeMe at [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}).

## 4. Relationship Disclosure Information

By using this Site, you acknowledge having read and understood BridgeMe's [Relationship Disclosure Information](./${AppRoute.Relationship}) before making an investment in any campaign. If you have any questions relating to BridgeMe's Relationship Disclosure information or more generally in respect to these Terms of Use, please email us at [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}).

## 5. Use of the Site by You

Your use of the Site is subject to all applicable local, provincial, state and federal laws and regulations. You may not use, allow, or enable others to use the Site, or knowingly condone use of the Site by others, in any manner that is, attempts to, or is likely to:

 1. be obscene, fraudulent, defamatory, libellous, indecent, discourteous, racially or ethnically offensive, harassing, threatening, abusive, pornographic or discriminatory;
 1. affect us adversely or reflect negatively on us, the Site, our goodwill, name or reputation or cause duress, distress, or discomfort to us or anyone else, or discourage any person, firm, or enterprise from using all or any portion, features, or functions of the Site, or from advertising, linking, or becoming a supplier to us in connection with the Site;
 1. send or result in the transmission of junk e-mail, chain letters, duplicative or unsolicited messages, or so-called "spamming" or "phishing";
 1. modify, disrupt, impair, alter, or interfere with the use, features, functions, operation, or maintenance of the Site or the rights or use and enjoyment of the Site by any other user;
 1. advocate or encourage any illegal activity;
 1. infringe upon or misappropriate the copyright, patent, trademark, trade secret, publicity rights or other intellectual property or proprietary rights of BridgeMe or any third party;
 1. violate the privacy of any individual, including users of the Site; or
 1. violate any applicable local, provincial, state or national laws or regulations (anywhere in the world).

In order to access certain products and services offered on the Site or as part of the account registration process, you may be required to provide information about yourself (such as identification, contact details and jurisdiction of residence, as well as more detailed personal information, such as in regard to your personal circumstances, financial circumstances, investment needs and objectives, investment knowledge, risk profile and investment time horizon). You agree that any registration information you give to BridgeMe will always be accurate, correct and up to date. You also agree that you will be the only authorized user of the Site under these Terms of Use and will be solely responsible for the confidentiality of any username and password supplied by you or provided to you. Should you become aware of any unauthorized use of your username or password with respect to any activity on the Site, you must immediately notify us via email or otherwise in writing. Upon receipt of such notice, we will take reasonable steps to stop any activity using your username or password, but neither BridgeMe, nor any of its respective directors, officers, employees, agents, representatives or affiliates can or will have any responsibility or liability to any person whose claim may arise for any claims with respect to the handling or mishandling of any transaction on the Site resulting from the unauthorized use of your username or password.

Unless you have been specifically permitted to do so in a separate agreement with BridgeMe, you agree that you will not reproduce, duplicate, copy, sell, trade or resell the Contents or any BridgeMe products and services for any purpose.

You agree that you are solely responsible for (and that BridgeMe has no responsibility to you or to any third party for) any breach of your obligations under these Terms of Use and for the consequences (including any loss or damage which BridgeMe may suffer) of any such breach.

Certain areas of the Site are available only to users who have been authorized by BridgeMe to access them. By accessing these portions of the Site and viewing its Contents, you agree to keep all such materials confidential.  You agree not to disclose or otherwise provide any material obtained from the Site, including information relating to the nature or content of the investment opportunities presented on the Site, to any person. Any non-confidential use must be authorized in writing and in advance by BridgeMe.

## 6. User Generated Content

You acknowledge and agree that you will be solely responsible for any content posted to the Site by you, including any events, news items, photos, videos, or comments ("User Content"). BridgeMe will not be liable in any way for any such User Content submitted. You further agree that you will not:

 - submit any User Content which is harassing, abusive, threatening, harmful, libelous or defamatory, encourages conduct that could constitute a criminal offense or give rise to civil liabilities, or is unlawful in any other way.
 - submit any User Content protected by intellectual property laws or by rights of privacy unless you own the rights thereto or have received all necessary consents. You shall be solely liable for any damage resulting from any infringement of copyright, trademark or other proprietary rights;
 - submit any User Content which contains a virus or other harmful components;
 - engage in activity that interferes with or disrupts the use of the Site by other users;
 - submit any User Content that encourages any illegal activities, or provides guidance or instructional activities about such illegal activities; or
 - make any false representation, including impersonation of any person or entity or misrepresentation of your affiliation with any person or entity.

You agree that no User Content submitted by you to the Site will:

 - violate any right of any individual, group, community or business, including but not limited to copyright, trademark, privacy, or other personal or proprietary rights;
 - be libelous or contain libelous or other otherwise unlawful, slanderous abusive, or obscene material, or constitute the misappropriation of trade secrets of any third party; or
 - disparage the products or services of any third party.

BridgeMe does not pre-screen, monitor or edit the User Content posted or submitted to the Site. However, BridgeMe reserves the right to edit, limit or remove any such User Content in its sole discretion. Notwithstanding, you shall remain solely responsible for any User Content you submit or post.

You may be exposed to User Content on the Site that is inaccurate, incomplete or unsuitable. Please notify BridgeMe (with the contact information posted at the bottom of this document) of any illegal or inappropriate User Content, and BridgeMe will take appropriate action. BridgeMe will ensure that any offending comments are removed and take appropriate action against the offender. BridgeMe will not be responsible for the User Content or accuracy of any information and shall not be responsible for any acts taken or decisions made based on such information.

BridgeMe may establish procedures and practices relating to the use of and participation in the Site. Such procedures and practices may include limitations on the number of entries and the size of files that may be submitted, limitations on the number and type of User Content submitted at any given time and the number of days content will be saved before deletion. BridgeMe will not be responsible for any User Content deleted by BridgeMe or otherwise, or for your inability to submit any User Content.

## 7. Submissions

You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information provided by you to BridgeMe (collectively, "Submissions"), are non-confidential and shall become the sole property of BridgeMe which owns exclusive rights, including all intellectual property rights, and will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgement or compensation to you.

## 8. Links to Third-Party Sites

Use of certain links on the Site will direct you away from the Site to third party websites. Such third party websites are not under the control of BridgeMe, and BridgeMe is not responsible for the contents of any such website, or any link contained in such website. The third party links included on the Site are provided for your convenience, and the inclusion of such links does not imply a recommendation or endorsement by BridgeMe of any such website or the products or services offered therein. If you decide to access any of the third party websites linked to the Site, you do so entirely at your own risk.  You acknowledge and agree that BridgeMe shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by, or in connection with the use of or reliance on any such third party websites. 

## 9. Privacy and Personal Information

For information about BridgeMe's treatment and protection of yours and other's personal information, please read our [Privacy Policy](/${AppRoute.Privacy}). This policy explains how BridgeMe treats your personal information, and protects your privacy, when you use the Site.

## 10. Intellectual Property Rights

The Site together with all trade-marks and other intellectual property displayed, distributed, or otherwise made available via the Site, is the exclusive property of BridgeMe, and its successors, assigns, licensors, and/or suppliers. Unless you have agreed otherwise in writing with BridgeMe, nothing in these Terms of Use gives you a right to use any of the Contents, BridgeMe's trade-marks or other intellectual property of BridgeMe.  You may not assign or transfer any of the Contents and you may not grant a license to use or access the Site to any party.

You may use the Site and the Contents solely for your non-commercial and limited personal use and for no other purposes. 

No information or statement contained in these Terms of Use or the Site shall be construed as conferring, directly or by implication, estoppel or otherwise, any license or right under any patent, copyright, trade-mark, or other intellectual property right of BridgeMe or any third party.  You must not alter, delete, or conceal any copyright or other notices contained on the Site, including notices on any of the Contents that you are permitted to download, transmit, display, print, or reproduce from the Site.

You may not allow any third party (whether or not for your benefit) to reproduce, modify, create derivative works from, display, perform, publish, distribute, disseminate, broadcast or circulate to any third party (including, without limitation, on or via a third party website), or otherwise use, any Contents without the express prior written consent of BridgeMe or its owner if BridgeMe is not the owner.

Any unauthorized or prohibited use of any Contents may subject you to civil liability, criminal prosecution, or both, under applicable federal, provincial, state and local laws.

The limited rights granted to you under these Terms of Use may be revoked by BridgeMe at any time for any reason whatsoever.

## 11. Electronic Signatures

By using our funding platform, you have consented to the electronic delivery of any and all Documents (as per below) relating to the investment in Issuers via the platform in lieu of receiving such Documents by regular mail. For this purpose, "Documents" means any and all communications and documents relating to your profile and investments in Issuers, including, but not limited to, notices, regulatory and informational material, trade confirmations and account statements.

You may revoke your consent to electronic delivery by providing notice to [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}) and further acknowledge that if you do so, paper copies of the Documents will then be received by regular mail.

BridgeMe applies electronic or digital signature technology **("E-signature")**, whether it is a computer-generated signature, an electronic copy of the Investor's true ink signature or otherwise. The Investor authorizes and instructs BridgeMe to accept and execute any and all such forms which are signed using an E-signature.  The Investor acknowledges and agrees that any forms signed using an E-signature via BridgeMe's platform (or sent by BridgeMe directly to the Investor) shall be treated by BridgeMe as valid and binding as the Investor's true ink signature. With forms provided by the Investor at any time using an E-signature, the Investor agrees to keep BridgeMe indemnified against any loss of any nature whatsoever arising to acting upon forms provided using an E-signature. The Investor acknowledges and agrees that BridgeMe may rely conclusively upon and shall incur no liability whatsoever including, without limitation, any losses (whether direct, indirect, consequential, in contract, tort, or otherwise) arising in respect of any action taken or omitted to be taken upon any instructions provided using an E-signature believed in good faith to be genuine or to be signed by the Investor. The foregoing shall not obligate BridgeMe to process instructions executed by E-signature. BridgeMe may decline to act on any E-signature instruction in its absolute discretion and intend to do so particularly in circumstances where BridgeMe is unable to verify whether an instruction has been provided by the Investor (or a party authorized to give instructions on behalf of the Investor).  If any instruction is submitted by the Investor and not acknowledged by BridgeMe, it is the Investor s obligation to contact BridgeMe to confirm receipt.

## 12. Third-Party Information

Any third-party content, data or publications made available through the Site are furnished by BridgeMe on an as-is basis for your convenience and information. Any opinions, advice, statements, services, offers, or other information made available by third parties, including program hosts, information providers, or any user of the Site, are those of the respective author(s) or publisher(s) and not of BridgeMe. BRIDGEME DISCLAIMS ANY WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED, THAT THE INFORMATION IN SUCH PUBLICATIONS IS ACCURATE OR COMPLETE.

## 13. No Warranty

YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK AND THAT THE SITE AND THE CONTENTS ARE PROVIDED "AS IS" AND "AS AVAILABLE."

IN PARTICULAR, BRIDGEME AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:

 1. YOUR USE OF THE SITE WILL MEET YOUR REQUIREMENTS,
 1. YOUR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
 1. ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SITE WILL BE ACCURATE OR RELIABLE, AND
 1. THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SITE WILL BE CORRECTED.

ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.

NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM BRIDGEME OR THROUGH OR FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF USE.

BRIDGEME FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

## 14. Limitation of Liability

YOU EXPRESSLY UNDERSTAND AND AGREE THAT BRIDGEME, AND ITS LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU IN CONNECTION WITH YOUR USE OF THE SITE, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY INCLUDING, BUT NOT LIMITED TO, ANY LOSS OF PROFIT, LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA SUFFERED, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS.

THE LIMITATIONS ON BRIDGEME'S LIABILITY TO YOU IN THIS SECTION SHALL APPLY WHETHER OR NOT BRIDGEME HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.

## 15. Termination

We may terminate your use of the Site and/or access to the Contents, features, functionality, products and services made available in connection therewith, at any time and for any reason, with or without cause, without prior notice to you and without any liability or further obligation of any kind whatsoever to you or any other party.

If you want to terminate your account with BridgeMe, you may do so by closing your account, where BridgeMe has made this option available to you.

BridgeMe may at any time terminate your use of the Site, including if:

 - you have breached any provision of these Terms of Use (or have acted in a manner which clearly shows that you do not intend to, or are unable to comply with the provisions of the Terms of Use);
 - BridgeMe is required to do so by law (for example, where the provision of the Site to you is, or becomes, unlawful); or
 - the provision of the Site to you by BridgeMe is, in BridgeMe's opinion, no longer commercially viable.

When these Terms of Use come to an end, all of the legal rights, obligations and liabilities that you and BridgeMe have benefited from, been subject to (or which have accrued over time while the Terms of Use have been in force) or which are expressed to continue indefinitely, shall be unaffected by such termination.

## 16. Indemnification

You agree to indemnify, defend, and hold harmless BridgeMe and its successors and assigns, and any of their respective officers, directors, employees, members, agents, representatives, licensors, advertisers, and suppliers from any liability, loss, claim, and expense (including reasonable legal fees) related to (a) your violation of these Terms of Use, and (b) your use of the Site.

We reserve the right to assume, at our sole expense, the exclusive defense and control of any such claim or action and all negotiations for settlement, or compromise, and you agree to fully cooperate with us in the defense of any such claim, action, settlement or compromise negotiations, as we may request. 

## 17. General

These Terms of Use, together with any Additional Terms and the [Privacy Policy](/${AppRoute.Privacy}) constitute the entire agreement between you and BridgeMe relating to your use and our provision of the Site.

You agree that BridgeMe may provide you with notices, including those regarding changes to these Terms of Use, by email, regular mail, or postings on the Site.

You agree that if BridgeMe does not exercise or enforce any legal right or remedy which is contained in these Terms of Use (or which BridgeMe has the benefit of under any applicable law), this will not be taken to be a formal waiver of BridgeMe's rights and that those rights or remedies will still be available to BridgeMe.

If any provision of these Terms of Use is held to be illegal, invalid or unenforceable, this will not affect any other provision of these Terms of Use and the agreement between you and us will be deemed amended to the extent necessary to make it legal, valid, and enforceable.

The laws of the Province of Ontario and the federal laws applicable therein shall govern these Terms of Use in all respects, without giving effect to conflicts of laws principles.

No e-mail address found on the Site may be harvested or otherwise used for purposes of solicitation.

## 18. Obtaining BridgeMe's Consent

To request the consent of BridgeMe for any of the actions for which such consent is required under these Terms of Use, please send an e-mail to [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}).  BridgeMe reserves the right to refuse any such requests in its sole discretion.

Last Updated: 2022-04-01`;

const TermsOfUse = () => {
  return (
    <Container size="2" pb="8">
      <Markdown>{CONTENT}</Markdown>
    </Container>
  );
};

export default TermsOfUse;

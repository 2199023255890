export const URLS = {
  LINKEDIN: "https://www.linkedin.com/company/bridgemecanada/about/",
};

export const EMAILS = {
  COMPLIANCE: "compliance@bridgeme.ca",
  SUPPORT: "support@bridgeme.ca",
};

export const PHONES = {
  COMPLIANCE: "(647)-490-6869",
  COMPLIANCE_LINK: "6474906869",
};

import { EnvIdentifier } from "enums/env";
import { ENV_DEMO } from "environments/demo";
import { ENV_DEV } from "environments/dev";
import { ENV_LOCAL } from "environments/local";
import { ENV_PROD } from "environments/prod";
import { ENV_STAGING } from "environments/staging";
import { EnvConfig } from "interfaces/env-config";

/** avoids crashing the app if no file is found */
const localEnvConfig = (): Partial<EnvConfig> | undefined => {
  try {
    const output = require("./local.config.json");
    return output;
  } catch (e) {
    // console.debug(e);
    return {};
  }
};

/** based on target REACT_APP_STAGE from build command */
const mainEnvConfig = (): EnvConfig => {
  switch (process.env.REACT_APP_STAGE as EnvIdentifier) {
    case "prod":
      return ENV_PROD;

    case "staging":
      return ENV_STAGING;

    case "demo":
      return ENV_DEMO;

    case "dev":
      return ENV_DEV;

    default:
      return ENV_LOCAL;
  }
};

const config: EnvConfig = {
  ...mainEnvConfig(),
  ...localEnvConfig(),
};

interface Config extends EnvConfig {
  auth0: {
    domain: string;
    audience: string;
    clientId: string;
  };
}

const output: Config = {
  /** shared config values here */
  auth0: {
    domain: "bridgeme.us.auth0.com",
    audience: "https://bridgeme.us.auth0.com/api/v2/",
    clientId: "dLVUMysaYZnIVpfQvFquMEmItddJqL3W",
  },

  /** export conditional config */
  ...config,
};

export default output;

import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Text,
} from "@radix-ui/themes";
import { showToast } from "components/common/toast-listener";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { EMAILS } from "enums/urls";
import ContactForm from "./form";

const TORONTO_ADDRESS_PARTS = [
  "Richmond-Adelaide Centre",
  "120 Adelaide St W, Suite 2500,",
  "Toronto, ON M5H 1T1, Canada",
];

const Contact = () => {
  const blurb = (
    <Text size={FONT_SIZE.text}>
      Would you like to know more about BridgeMe or ask us a question?
      <br />
      We are looking forward to hearing from you and will be pleased to help.
    </Text>
  );

  return (
    <Flex direction="column">
      <div className="soft-header">
        <Container size="2">
          <Flex
            pt={PAD_LG.pv}
            pb={PAD_LG.pv}
            pl={PAD_LG.ph}
            pr={PAD_LG.ph}
            direction="column"
            gap={GAP.sm}
          >
            <Heading align="center" size="8">
              Connect with us
            </Heading>

            <Grid
              gap={GAP.md}
              columns={{
                initial: "1",
                md: "2",
              }}
            >
              <Box className="show-lt-md">{blurb}</Box>
              <ContactForm />
              <Box className="show-gt-md">{blurb}</Box>
            </Grid>
          </Flex>
        </Container>
      </div>

      <Container size="3">
        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{
            initial: "1",
            md: "2",
          }}
          gap={GAP.md}
        >
          <Flex justify="center">
            <img
              className="show-gt-md graphic-lg"
              src="/images/contact/collage_1.png"
            />
            <img
              className="show-lt-md graphic-lg"
              src="/images/contact/collage_2.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <Heading weight="light" color="indigo" size={FONT_SIZE.title}>
              Contact us
            </Heading>

            <Heading weight="light" size={FONT_SIZE.header}>
              Toronto
            </Heading>

            <Text
              size={FONT_SIZE.text}
              className="clickable"
              onClick={() => {
                navigator.clipboard.writeText(TORONTO_ADDRESS_PARTS.join("\n"));
                showToast({
                  title: "Success",
                  description: "Copied to clipboard!",
                });
              }}
            >
              {TORONTO_ADDRESS_PARTS.map((text, i) => {
                if (i === 0) {
                  return text;
                }

                return (
                  <>
                    <br />
                    {text}
                  </>
                );
              })}
            </Text>

            <Text size={FONT_SIZE.subheader}>
              <Link href={`mailto:${EMAILS.SUPPORT}`}>{EMAILS.SUPPORT}</Link>
            </Text>
          </Flex>
        </Grid>
      </Container>
    </Flex>
  );
};

export default Contact;

import { Box, Button, Code, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { FLEX_DIR_MD, FLEX_DIR_SM, FONT_SIZE, GAP } from "enums/radix-layout";
import { AppRoute } from "enums/router";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";

interface TeamMember {
  name: string;
  title: string;
  image: string;
  content_md: string;
}

const TEAM: TeamMember[] = [
  {
    name: "Nicholas Bong",
    title: "CEO & ULTIMATE DESIGNATED PERSON",
    image: "./images/about-us/nick.png",
    content_md: `Serving as the Chief Executive Officer and Ultimate Designated Person at BridgeMe Securities Inc., Nicholas Bong steers the firm's strategy and direction through his impactful leadership. Holding an MBA from the Smith School of Business at Queen's University and an Honours Bachelor of Arts in Economics from the University of Waterloo, Nicholas brings over a decade of experience from various functions at TD Bank, spanning Treasury & Balance Sheet Management, Risk Management, and Commercial Credit Product Management.`,
  },
  {
    name: "Alan Yu",
    title: "CHIEF OPERATING OFFICER",
    image: "./images/about-us/alan.png",
    content_md: `As the Chief Operating Officer of BridgeMe Securities Inc., Alan Yu ensures seamless operational delivery from ideation to execution. Armed with an MBA from the Smith School of Business at Queen's University and a Bachelor of Commerce from Ryerson University (now Toronto Metropolitan University), Alan boasts a 20-year journey in Financial Services, covering Retail Banking, Asset Management, Risk Management, Finance, Consulting, and large-scale transformation. His wealth of experiences provides valuable strategic and thought leadership to support BridgeMe's journey.`,
  },
  {
    name: "Martin Nigro",
    title: "CHIEF COMPLIANCE OFFICER",
    image: "./images/about-us/martin.png",
    content_md: `Martin Nigro, Chief Compliance Officer of BridgeMe Securities Inc., spearheads the development and execution of the firm's compliance program. Also the Founder and Principal of Edge Compliance Advisors, Martin advises securities regulated firms on building comprehensive compliance blueprints and delivering bespoke solutions. With a background that includes six years as the Chief Compliance Officer for HSBC Canada and diverse roles at CIBC Asset Management and Mercer in Ireland, Martin holds a Bachelor of Commerce in Finance and Marketing from Concordia University and is a Certified Financial Planner (CFP) and a certified professional by the Association of Certified Anti-Money Laundering Specialists (ACAMS).`,
  },
  {
    name: "Carl Wong",
    title: "CHIEF TECHNOLOGY OFFICER",
    image: "./images/about-us/carl.png",
    content_md: `In the role of Chief Technology Officer at BridgeMe Securities Inc., Carl Wong draws upon over a decade of experience in software development to deliver scalable, tech-enabled solutions. A distinguished graduate from the University of Waterloo (BMath, Combinatorics and Optimization) and Wilfrid Laurier University (BBA, Finance), Carl has served as a Full-Stack Web Developer in various industries, showcasing his expertise in designing, implementing, and servicing enterprise-grade solutions for clients of all sizes.`,
  },
  {
    name: "Richard Inciong, CFA",
    title: "HEAD OF RISK & FINANCE",
    image: "./images/about-us/richard.png",
    content_md: `With a rich and diverse professional background spanning over ten years, Richard Inciong brings expertise in Treasury & Balance Sheet Management, Finance & Financial Analysis, Data Analytics, IT, and Telecommunications. As a seasoned professional, he specializes in analytics and finance, excelling in Risk Management, Financial Analysis, and Valuations. Richard's commitment to making a positive impact is evident through leadership roles within the community, complementing his numerous academic and professional accolades. Throughout his career, he has earned recognition for his dedication to innovation and strategic risk management.`,
  },
];

const People = () => {
  const navigate = useNavigate();

  return (
    <Grid columns={{ initial: "1", md: "2" }} gap={GAP.sm} width="auto">
      <Flex direction="column" gap={GAP.xs}>
        <Heading size={FONT_SIZE.header}>People and leadership</Heading>
        <Text size={FONT_SIZE.subtext}>
          Our greatest asset is our team. The entrepreneurial spirit,
          dedication, and experience in finance, and technology, helps us serve
          our clients and serve effective financial services.
        </Text>

        <Box>
          <Button onClick={() => navigate(`/${AppRoute.Contact}`)}>
            Contact Us
          </Button>
        </Box>
      </Flex>

      {TEAM.map((m, i) => (
        <Flex key={`team-member-${i}`} gap={GAP.xs} direction={FLEX_DIR_MD}>
          <Box>
            <img src={m.image} width="160px" height="auto" />
          </Box>
          <Box>
            <Flex gap="1" direction={FLEX_DIR_SM}>
              <Code
                variant="solid"
                style={{
                  width: "min-content",
                  whiteSpace: "nowrap",
                  paddingLeft: "var(--space-2)",
                  paddingRight: "var(--space-2)",
                }}
              >
                {m.name.toUpperCase()}
              </Code>
              <Code
                variant="solid"
                color="yellow"
                size="1"
                style={{
                  width: "min-content",
                  whiteSpace: "nowrap",
                  paddingLeft: "var(--space-2)",
                  paddingRight: "var(--space-2)",
                }}
              >
                {m.title.toUpperCase()}
              </Code>
            </Flex>
            <Text size={FONT_SIZE.subtext}>
              <Markdown>{m.content_md}</Markdown>
            </Text>
          </Box>
        </Flex>
      ))}
    </Grid>
  );
};

export default People;

import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { Box, Button, Container, DropdownMenu, Flex } from "@radix-ui/themes";
import { AppRoute } from "enums/router";
import { t } from "i18next";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "translations/i18n";

const ENABLE_LANGUAGE = false;

interface NavButton {
  i18Key: string;
  active: boolean;
  onClick: () => void;
}

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(i18n.language);

  const NAV_BUTTONS: NavButton[] = [
    {
      i18Key: "about",
      active: location.pathname.includes(AppRoute.About),
      onClick: () => navigate(`/${AppRoute.About}`),
    },
    {
      i18Key: "services",
      active: location.pathname.includes(AppRoute.Services),
      onClick: () => navigate(`/${AppRoute.Services}`),
    },
    {
      i18Key: "contact",
      active: location.pathname.includes(AppRoute.Contact),
      onClick: () => navigate(`/${AppRoute.Contact}`),
    },
  ];

  const renderButton = (b: NavButton) => (
    <Button
      key={`header-${b.i18Key}`}
      size="3"
      variant="soft"
      radius="none"
      style={{
        borderBottom: b.active ? "4px solid" : "4px solid rgba(0,0,0,0)",
      }}
      onClick={() => b.onClick()}
    >
      {t(b.i18Key)}
    </Button>
  );

  const langButton: NavButton = {
    i18Key: language === "en" ? "FR" : "EN",
    active: false,
    onClick: () => {
      const nextLanguage = (() => {
        switch (language) {
          case "en":
            return "fr";

          default:
            return "en";
        }
      })();

      console.debug(`Changing language to ${nextLanguage}`);

      setLanguage(nextLanguage);
      i18n.changeLanguage(nextLanguage);
      window.location.reload();
    },
  };

  return (
    <Container size="3" p="4">
      <Flex
        justify="between"
        mt={{
          initial: "0",
          md: "4",
        }}
        mb={{
          initial: "0",
          md: "4",
        }}
      >
        <img
          alt="logo"
          src={`${window.origin}/logo.svg`}
          className="show-gt-md clickable"
          style={{
            height: "50px",
          }}
          onClick={() => navigate(AppRoute.Root)}
        />

        <img
          alt="icon"
          src={`${window.origin}/icon-dark.svg`}
          className="show-lt-md clickable"
          style={{
            height: "25px",
          }}
          onClick={() => navigate(AppRoute.Root)}
        />

        <Box className="show-gt-md">
          <Flex direction="row" gap="1">
            {NAV_BUTTONS.map((b) => renderButton(b))}
            {ENABLE_LANGUAGE && renderButton(langButton)}
          </Flex>
        </Box>

        <Box className="show-lt-md">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button variant="soft">
                <HamburgerMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {NAV_BUTTONS.map((b, i) => (
                <DropdownMenu.Item
                  key={`header-menu-${i}`}
                  onClick={() => b.onClick()}
                >
                  {t(b.i18Key)}
                </DropdownMenu.Item>
              ))}

              {ENABLE_LANGUAGE && (
                <DropdownMenu.Item onClick={() => langButton.onClick()}>
                  {t(langButton.i18Key)}
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Box>
      </Flex>
    </Container>
  );
};

export default Header;

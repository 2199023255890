import { Flex, Heading } from "@radix-ui/themes";
import { PAD_LG } from "enums/radix-layout";
import { t } from "i18next";
import "./establish.scss";

const Establish = () => {
  return (
    <div id="Establish" className="soft-header">
      <div id="Title">
        <Flex
          direction="column"
          gap="4"
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
        >
          <Heading size="9">
            {t("establish-line-1")}
            <br />
            {t("establish-line-2")}
          </Heading>

          <Heading className="show-gt-md" size="6" weight="light">
            Navigate with confidence: Expert finance, regulatory mastery, tech
            excellence - Your path to simplified private capital markets.
          </Heading>
        </Flex>
      </div>
      <div
        id="Image"
        style={{ backgroundImage: "url('/images/landing/meeting.png')" }}
      ></div>
    </div>
  );
};

export default Establish;

import { Box, Container, Flex, Link, Text } from "@radix-ui/themes";
import { GAP, PAD_SM } from "enums/radix-layout";
import { URLS } from "enums/urls";

interface NavButton {
  alt: string;
  imgPath: string;
  onClick: () => void;
}

const ICON_BUTTONS: NavButton[] = [
  {
    alt: "linkedin",
    imgPath: "/images/linkedin.svg",
    onClick: () => window.open(URLS.LINKEDIN),
  },
];

const Footer = () => {
  const renderButton = (b: NavButton) => (
    <Box>
      <Link key={`header-${b.imgPath}`} onClick={() => b.onClick()}>
        <img alt={b.alt} src={b.imgPath} />
      </Link>
    </Box>
  );

  return (
    <Container
      size="3"
      style={{
        backgroundColor: "#000a23",
        color: "white",
      }}
    >
      <Flex
        pt="4"
        pb="4"
        pl={PAD_SM.ph}
        pr={PAD_SM.ph}
        gap={GAP.sm}
        justify="end"
      >
        <Text>©BridgeMe</Text>
        {ICON_BUTTONS.map((b) => renderButton(b))}
      </Flex>
    </Container>
  );
};

export default Footer;

import { AppRoute } from "enums/router";
import { Navigate, Route, Routes } from "react-router-dom";
import AboutUs from "./about";
import Contact from "./contact";
import Landing from "./landing";
import PrivacyPolicy from "./privacy";
import RelationshipDisclosure from "./relationship";
import Services from "./services";
import TermsOfUse from "./terms";

export const RENDER_RDI_AS_PDF = true;

const MainRouter = () => {
  return (
    <Routes>
      <Route path={AppRoute.Root} element={<Landing />} />

      <Route path={AppRoute.About} element={<AboutUs />} />
      <Route path={AppRoute.Contact} element={<Contact />} />
      <Route path={AppRoute.Privacy} element={<PrivacyPolicy />} />
      {!RENDER_RDI_AS_PDF && (
        <Route
          path={AppRoute.Relationship}
          element={<RelationshipDisclosure />}
        />
      )}
      <Route path={AppRoute.Services} element={<Services />} />
      <Route path={AppRoute.Terms} element={<TermsOfUse />} />

      <Route path="*" element={<Navigate to={AppRoute.Root} />} />
    </Routes>
  );
};

export default MainRouter;

type Padding = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

interface PaddingDef {
  initial: Padding;
  md?: Padding;
}

export const PAD_LG: {
  pv: PaddingDef;
  ph: PaddingDef;
} = {
  pv: {
    initial: "8",
    md: "9",
  },
  ph: {
    initial: "6",
  },
};

export const PAD_SM: {
  pv: PaddingDef;
  ph: PaddingDef;
} = {
  pv: {
    initial: "8",
  },
  ph: {
    initial: "6",
  },
};

export const GAP: {
  xs: PaddingDef;
  sm: PaddingDef;
  md: PaddingDef;
  lg: PaddingDef;
} = {
  xs: {
    initial: "4",
  },
  sm: {
    initial: "4",
    md: "6",
  },
  md: {
    initial: "6",
    md: "8",
  },
  lg: {
    initial: "8",
    md: "9",
  },
};

type FontSize = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

interface FontSizeDef {
  initial: FontSize;
  md?: FontSize;
}

export const FONT_SIZE: {
  title: FontSizeDef;
  header: FontSizeDef;
  subheader: FontSizeDef;
  text: FontSizeDef;
  subtext: FontSizeDef;
} = {
  title: {
    initial: "9",
    md: "8",
  },
  header: {
    initial: "8",
    md: "7",
  },
  subheader: {
    initial: "6",
    md: "5",
  },
  text: {
    initial: "5",
    md: "4",
  },
  subtext: {
    initial: "4",
    md: "3",
  },
};

type FlexDirection = "column" | "column-reverse" | "row" | "row-reverse";

export const FLEX_DIR_MD: { initial: FlexDirection; md: FlexDirection } = {
  initial: "column",
  md: "row",
};

export const FLEX_DIR_MD_REVERSE: {
  initial: FlexDirection;
  md: FlexDirection;
} = {
  initial: "column-reverse",
  md: "row",
};

export const FLEX_DIR_SM: { initial: FlexDirection; sm: FlexDirection } = {
  initial: "column",
  sm: "row",
};

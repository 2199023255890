import { Container } from "@radix-ui/themes";
import { AppRoute } from "enums/router";
import { EMAILS, PHONES } from "enums/urls";
import Markdown from "react-markdown";

const CONTENT = `## General

National Instrument 31-103 - Registration Requirements, Exemptions and Ongoing Registrant Obligations requires us
to disclose information that a reasonable investor would expect to know about its relationship with the firm,
including but not limited to, the services we offer, the related fees and expenses, and any material conflicts
the firm or its representatives may have with an investor and how the firm attempts to mitigate them. We encourage
you to read this document carefully to understand its contents. If you have any questions relating to the contents
of this document, please contact us by telephone at [${PHONES.COMPLIANCE}](tel:+${PHONES.COMPLIANCE_LINK}) or by e-mail to
[${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}). If there
is a significant change to the information contained in this document, it will be provided on
[www.bridgeme.ca](https://bridgeme.ca) as soon as reasonably possible.

## Registration

BridgeMe Securities Inc. **("BridgeMe", "BM", "we", "us")** is registered as an exempt market dealer in Alberta, British Columbia, Manitoba, Ontario, and Saskatchewan. BridgeMe's head office is located at 120 Adelaide Street West, Suite 2500, Toronto, Ontario M5H 1T1, and its principal regulator is the Ontario Securities Commission.

## Business Activities

BridgeMe operates a funding platform, more specifically an online platform that allows businesses ("Issuers") to connect with prospective investors ("Investors") in order to seek investment capital on a private placement basis. The funding platform provides a platform for growing businesses seeking capital to connect with Investors that may, for example, be seeking investment opportunities in early-stage companies.

Investments will be completed on a prospectus-exempt basis in reliance on available prospectus exemptions, including primarily:

  1. the start-up crowdfunding prospectus exemption;
  1. the offering memorandum prospectus exemption; and
  1. the accredited investor prospectus exemption.

### Business Campaigns

Each offering of securities by an Issuer via BridgeMe's funding platform is referred to as a **"Campaign"**.

BridgeMe assists Issuers in bringing their investment opportunities to market, which will include a due diligence process to screen which Issuers will be permitted to launch a Campaign to raise capital via the funding platform. This process will include:

  1. due diligence to determine that the Issuer meets certain criteria set out by BridgeMe, such as financial projections, business plan, and the appropriate legal documentation such as incorporation documents and shareholder agreements, and
  1. verifying that an Issuer is fulfilling the criterion required to rely on the applicable prospectus exemption.

"Know-Your-Product" **("KYP")** information that BridgeMe collects from Issuers is used by the firm to help it determine whether an Investor's purchase of securities of the Issuer as part of its Campaign is suitable for the Investor.

### Investor-Facing Obligations, including KYC and Suitability

Via an electronic on-boarding process, BridgeMe collects "Know-Your-Client" **("KYC")** information from Investors that sign-up as users of the funding platform. KYC information includes details in respect to an Investor's investment needs and objectives (including the time horizon for its investments), financial circumstances (including net worth, income, current investment holdings, and employment status), investment knowledge and risk tolerance. We use and hold your KYC information in your profile to, among other things, confirm your identity, comply with our obligations under Canadian anti-money laundering and anti-terrorist financing legislation, as well as to determine that any investment action taken, recommended, or decided upon for you is suitable and puts your interest first.

**BridgeMe is required under securities law to conduct a suitability assessment prior to accepting an Investor's instruction to purchase securities of an Issuer. Investors are advised that BridgeMe uses an automated system to determine whether a purchase of a security is suitable for an Investor before accepting an instruction from them to buy that security on the funding platform. This automated system provides Investors with the ability to purchase securities of an Issuer during a Campaign without interruption – from the point of initiating an investment to providing payment for the investment. All rights available to Investors for the investment remain unchanged.**

**The use of an automated suitability system, while also used by certain other Canadian funding platforms, is novel under securities laws. You may contact a BridgeMe dealing representative at any time for more information about the firm's automated system or to speak about your investment.**

**In the event a particular situation requires, BridgeMe's dealing representatives may also employ a manual suitability determination process.**

## Investor Funds Held In Trust

The funds to purchase securities of an Issuer may be delivered by an Investor through online payment methods. Online payments are processed by an independent third-party payment merchant who then sends the collected funds via an Electronic Funds Transfer to a dedicated BridgeMe account. BridgeMe holds these payments made by Investors in trust in an account at a Canadian Financial Institution. Two signatures from designated staff are required to transfer funds from the account.

Upon the closing of a Campaign, the Issuer reviews the subscription documentation for each investment and accepts or rejects the Investor's investment. If the Investor's subscription is accepted, the funds are released from the Canadian Financial Institution to the issuer (minus fees and commission), at which point, delivery of proof of ownership of the Issuer's securities is electronically delivered to the Investor, unless otherwise stated in the Issuer's offering or subscription documentation that proof of ownership is to be delivered by other means.

Disbursements to Issuers are reconciled at the end of the month within the firm's account for funds held in trust.

If the Campaign does not close or if the Investor's subscription is rejected, the Investor's funds are returned to the Investor via bank transfer within 7 business days.

Campaigns listed on the funding platform may have a minimum aggregate investment amount equal to zero. If an offering has a minimum higher than zero, then at least this minimum must be met for the Campaign to be 'successful'. If the minimum is zero, then the Campaign is assumed to always be successful. If there is no minimum, this is stated on the listing summary 'Campaign Tile'. Campaigns on the platform also have a 'target' amount, which may be equal or higher than the Minimum Amount.

In addition, there may be 'rolling' closes where the invested funds are disbursed to the Issuer at multiple times. Rolling closes can be applied for distributions conducted under certain prospectus exemptions, such as the Friends and Family and Business Associates exemption, the Accredited Investors exemption, and/or the Offering Memorandum exemption. The first rolling closing of an offering may occur once the minimum amount has been reached.

BridgeMe indicates the amounts an Issuer has raised during a Campaign through an online tracker feature. Amounts shown in the tracker may include all capital raised by the Issuer for an issuance, both on and off the platform.

## Fees and Expenses

BridgeMe charges Issuers a due diligence and administrative services fee, regardless of whether the Issuer ultimately launches a Campaign or not, and whether or not the Campaign ultimately closes or not. BridgeMe also earns an agency fee / commission from the Issuer, which is calculated as a percentage (%) of the dollar-value raised by the Issuer in a successful campaign. The amount of this agency fee / commission will be disclosed in the offering documents available on the funding platform as part of the relevant Issuer's Campaign. In certain instances, BridgeMe may pay third party referral fees for referring the Issuer to BridgeMe. Such a referral fee is paid directly by BridgeMe out of the fees it earns in connection with the Campaign.

Effectively, the agency fee / commission is deducted from the "use of proceeds" that would otherwise be available to the Issuer upon the successful closing of a Campaign. This reduced the amount of funds otherwise available to the Issuer to operate its business, including to repay any debt incurred by the Issuer.

No fees or expenses are charged directly or paid by an Investor.

## Investment Risks

As an exempt market dealer, BridgeMe distributes "exempt market" securities, meaning securities that have not been qualified by a prospectus and which are typically not listed on any public exchange. No securities commission or similar regulatory authority in Canada will review an Issuer's offering documents or in any pass upon the merits of the securities offered thereunder.

**Exempt market securities are generally illiquid as they are not freely tradeable via an exchange, are subject to transfer and resale restrictions and often no market is expected to develop for their sale or transfer. For this and other reasons, exempt market securities are risky investments, and an Investor can lose all the money it invests in such securities.**

Additionally:

  1. exempt market securities are not covered by deposit or investor protection insurance;
  1. private Issuers are not subject to the same ongoing disclosure obligations as "reporting issuers" (e.g., publicly listed companies);
  1. private Issuers are often subject to a greater degree of "key person" risk than more widely held companies;
  1. there can be no assurances that an investment will maintain its net acquisition value or produce any projected income or capital return; and
  1. investment values change frequently, and past performances may not be repeated.

The risks highlighted above are not exhaustive. Risk disclosures specific to each Issuer that launches a Campaign on the funding portal will be contained within that Issuer's offering documents. **Offering documents and the risks associated with the securities of any specific Issuer should be considered thoroughly by an investor and/or by the Investor's professional advisors before making an investment.**

## Use of Borrowed Funds

Using borrowed money to finance the purchase of securities, also called 'leveraging', involves greater risk than a purchase using cash resources. If you are considering borrowing money to purchase securities, you should be aware that a leveraged purchase involves greater risk than a purchase using available cash resources only. To what extent a leveraged purchase involves undue risk is a decision that needs to be made by you and will vary depending on your personal circumstances. In particular, you should be aware of the terms of any loan that is secured by the securities. If you borrow money to purchase securities, your responsibility to repay the loan, provide top up payments, and pay interest as required by its terms, remains the same even if the value of the securities purchased declines or the value of the securities goes to zero.

Under these circumstances, investors who leverage their investments are advised to have adequate financial resources available both to pay interest and also to reduce the loan if borrowing arrangements require such a payment.

## Cyber Security Risks

BridgeMe is dependent upon information technology systems which may be at risk for cyber security breaches. BridgeMe collects and protects sensitive information of individuals as part of its KYC and information security protocols. BridgeMe monitors and updates its system for protecting personal information on a continual basis.

BridgeMe also utilizes the services of third parties in the collection of personal information and identity verification as part of its KYC obligations as a registered dealer. Third parties may also experience cyber security breaches, maintain inconsistent levels of cyber security monitoring, or provide inadequate safeguards within their services provided to BridgeMe which is outside the control of BridgeMe. BridgeMe mitigates these risks through scheduled audits of its third-party providers to assess their ongoing ability to protect the personal information of Investors.

## Confidentiality and Privacy

Protecting Investors' confidential information is an integral part of our business values. We are committed to safeguarding this information in an appropriate and professional manner. As part of safeguarding Investor information, BridgeMe uses a cloud-based service and servers located in North America and Europe. BridgeMe has adopted a privacy policy to ensure confidential information is only used according to expectations, including:

  - All employees are accountable for protecting confidential information held by us or transferred to a third party for processing.
  - We do not sell Investors' confidential information.
  - We limit the collection of confidential information to only that information which is required for the purposes identified.
  - We use or disclose confidential information only for the purpose for which it was collected, unless we obtain the relevant consents, or the use or disclosure is authorized by law.
  - Confidential information is kept as accurate, complete, and up-to date as necessary, relevant to its use.
  - When requested, we will provide an Investor with certain access to its information. We will correct or amend any personal information if it is inaccurate or incomplete.
  - Confidential information may be delivered to the Ontario Securities Commission or to other legal or regulatory authorities as may be required.

By using our funding platform, you consent to the use and safeguarding of your personal information as per the above and as per our [Privacy Policy](/${AppRoute.Privacy}).

## Conflicts of Interest

Under applicable securities laws, BridgeMe is required to address and manage existing, as well as reasonably foreseeable, material conflicts in the best interest of Investors.

A conflict of interest can include any circumstance where:
  - the interests of different parties, such as the interests of the firm and those of an Investor, are inconsistent or divergent;
  - the firm or one of its registered representatives may be influenced to put their interests ahead of an Investor's interests; or
  - monetary or non-monetary benefits available to the firm or a registered representative, or potential detriments to which they may be subject, may compromise the trust that a reasonable Investor has in the firm or the individual.

Whether a conflict is "material" or not depends on the circumstances. In determining whether a conflict is material, BridgeMe will typically consider whether the conflict may be reasonably expected to affect the decisions of the Investor in the circumstances, and/or the recommendations or decisions of the firm or its registered representatives in the circumstances.

BridgeMe's employees must report to BridgeMe's Chief Compliance Officer any situation that is known or might give rise to a real or perceived material conflict of interest.

BridgeMe will avoid conflicts which are prohibited by law as well as conflicts which cannot effectively be managed in an Investor's best interest. In respect to material conflicts of interest that will be managed, the firm has adopted certain policies and procedures (as in relation to such things as Outside Activities, Staff Personal Trading, etc.) to:

  - minimize the occurrence of such conflicts or to deal fairly where those conflicts cannot be avoided;
  - establish internal controls and review processes in respect to such conflicts;
  - determine the level of risk that the conflict of interest raises; and
  - respond appropriately to such conflicts.

In addition to other measures that will be taken to manage existing and reasonably foreseeable material conflicts of interest, BridgeMe will typically provide Investors with disclosure in respect to the potential conflict. It is important that Investors read this disclosure to help inform their decision when evaluating the firm's business practices, conflicts management and overall performance on an ongoing basis. The Canadian Securities Administrators **(the "CSA")** note that conflict disclosure is critical to an Investor's ability to make an informed decision about how to manage and evaluate its relationship with a firm.

A summary of the specific material conflicts of interest identified by BridgeMe are set out below. **Material conflicts of interest that may arise as a result of a relationship between BridgeMe (or its registered individual(s)) and a specific Issuer will be disclosed within the offering documents of that specific Issuer.**

### Outside Activities

BridgeMe's employees may or may wish to engage in activities that are additional to their employment with the firm, including, for example, outside employment, directorships or board or committee memberships with other companies, foundations, charities, or industry trade groups.

Potential material conflicts of interest can arise from a BridgeMe employee engaging in these or other outside activities for various reasons, including the time commitment required or the position held by the employee in respect of these outside activities. Specific material conflicts of interest can arise if the outside activity puts an employee in a position of influence over an Issuer, provides the employee with inside information in respect of an Issuer or may cause the employee to serve their outside responsibilities over their responsibilities to Investors. An outside activity may also cause confusion as to which entity(ies) the employee is acting for in a given situation.

BridgeMe expects that all board members, management and employees **(collectively, "employees")** will avoid any activity, interest or association which might interfere or appear to interfere with the independent exercise of their judgment in the best interest of BridgeMe, Investors or the public. Employees must avoid any situation in which their personal interest conflict with their duties at BridgeMe.

All outside activities must be disclosed and approved by BridgeMe's Chief Compliance Officer. All of the Chief Compliance Officer's outside activities must be disclosed and approved by BridgeMe's Chief Executive Officer. An outside activity will not be approved if any existing or reasonably foreseeable material conflict of interest associated with the outside activity cannot be managed in the best interest of Interests.

### Staff Overlap in Respect to Revenue Generation and Compliance

Given the relatively small size of BridgeMe, there is overlap in the firm's staff between compliance responsibilities, dealing activities and potential revenue generation activities. For example, Nicholas Bong is the firm's President and Chief Executive Officer, and thus is responsible to supervise the activities of the firm that are directed towards compliance with securities legislation, and he is also a principal shareholder and is registered as a dealing representative of the firm.

Accordingly, the firm has staff that is responsible for compliance activities, as well as being involved in revenue generation activities and deciding on the firm's dealing activities. The CSA have noted that if a firm's compliance staff's compensation is tied to the sales or revenue generation of the firm overall or the registered individuals that they supervise, there is an inherent conflict of interest that may cause them to put their interests ahead of clients' interests.

In addition to disclosing this potential conflict of interest to you, BridgeMe manages this conflict by adhering to its well-developed compliance policies and procedures, including conducting proper KYC, KYP and suitability assessments when required. Additionally, the firm employs a Chief Compliance Officer, Martin Nigro, who is not registered as a dealing representative.

### Personal Trading

Employee personal trading can create a material conflict of interest for several reasons, including that employees with knowledge of Issuers whose securities have been, or are contemplated to be, offered via the funding platform could use that information for their own benefit.

All Employees of BridgeMe are prohibited from trading for their own account any security in which they have inside or non-public information. Employees must obtain approval from the Chief Compliance Officer before purchasing an Issuer's security that has been offered on the funding platform (or that is contemplated to be offered on the funding platform) for their own account.

### Gifts and Entertainment

Employees may offer or receive a gift or entertainment which could compromise or give the impression of compromising their independence. BridgeMe manages this conflict of interest by maintaining internal policies and procedures that: (i) require the tracking of gifts and entertainment received/given, (ii) set a reasonably acceptable dollar value limit for gifts and entertainment that may be received/given, and (iii) requires pre-approval for certain types and value limits of gifts and entertainment.

### Referral Arrangements

We do not pay any third parties to refer any Investors to the Firm, nor do we receive any compensation for the referral of any Investors to any third-party service providers. If we enter into any such arrangements in the future, we will provide appropriate disclosure in a timely manner.

From time-to-time, we may enter into arrangements whereby we will provide compensation to incubators, accelerators or other third-party service providers who may source, or provide information in respect to, potential Issuers to us whether or not such Issuers then launch a Campaign on our funding platform.

## Trade Confirmations and Account Statements

As an exempt market dealer, BridgeMe is required to and does promptly provide a trade confirmation to an Investor that purchases securities of an Issuer via the funding platform.

BridgeMe sends a notification to Investors on a quarterly basis requesting them to log-in and review their account activity report and update pertinent information for their account if necessary. The content of quarterly reports to Investors includes the date of any transactions, the name of any securities transacted, the number of securities transacted, the price per security and the total value of the transaction. The firm does not send a notification to review a quarterly report to an Investor if a trade was not made in the previous quarter.

Investors can log-in at any time and generate and review monthly account information as well.

BridgeMe delivers trade confirmations and account information to Investors electronically and does not physically mail such information to Investors. See the "Electronic Signatures and the Delivery of Documents" section below in that regard.

## Benchmarks

An investment performance benchmark is a standard against which the performance of an investment or an investment portfolio may be measured. An Investor may wish to use published investment performance benchmark information to measure the performance of its investment portfolio against an index of securities that is reasonably reflective of the composition of its investment portfolio.

BridgeMe does not provide benchmark information to Investors. The exempt market securities made available via the firm's funding portal are typically not freely tradeable or listed on any stock exchange. For this and other reasons, BridgeMe does not believe there are meaningful benchmarks for the securities distributed via its funding platform.

## Dispute Resolution

BridgeMe has adopted a policy relating to complaint examination and dispute resolution. Under the policy, BridgeMe's Chief Compliance Officer, is responsible for handling complaints.

If we receive a complaint from you, we will provide you with a written acknowledgment within 48 hours of the complaint being received.

BridgeMe will then attempt to resolve your complaint as soon as possible. Our decision will include a summary of the complaint, the results of our investigation, and our decision to make an offer to resolve the complaint or deny it, and an explanation of our decision.

If we cannot resolve your complaint within 5 business days of receipt, we will write to you and explain why, and tell you who to contact if you require further information and when you may expect a response. The investigation of your complaint will normally be handled internally; however, we may seek outside assistance, if needed, to properly investigate your complaint or because of a conflict of interest.

Under normal circumstances, you will receive a written response to your complaint as soon as possible and no more than 90 days after your complaint is received. If the investigation of your complaint is complex and lengthy so that more than 90 days is required, we will inform you and let you know when to expect our response.

At the time we receive your complaint and at the time we provide you with our response to same, we will also provide you with the following information:

  1. a description of our obligation, if any, to make an independent dispute resolution or mediation service available to you;
  1. the steps that you are required to take in order for such independent dispute resolution or mediation service to be made available to you; and
  1. the name of the independent dispute resolution or mediation service that will be made available to you and contact information for the service.

You will generally have the right to independent dispute resolution or mediation being made available to you at our expense if:

  1. after 90 days of our receipt of the complaint, we have not given you written notice of our decision in respect of the complaint; or
  1. within 180 days of your receipt of written notice of our decision in respect of the complaint, you have notified the independent dispute resolution or mediation service specified by us that you wish to have the complaint considered by the service.

There are limitations on your ability to have a complaint resolved at our expense by an independent dispute resolution or mediation service. For example, the Ombudsman for Banking Services and Investments **("OBSI")** will typically be the third-party organization to whom complaints may be referred. Under OBSI's rules, you must refer the complaint to them within six years of the day when you first knew or reasonably ought to have known of an act or omission that is a cause of or contributed to the complaint. Also, you must agree that, for the purpose of OBSI's consideration of the complaint, the amount claimed (if any) will be no greater than $350,000.

If you wish to serve a lawsuit against BridgeMe and/or one of its employees, such service may be made at the head office address of the firm which is 120 Adelaide Street West, Suite 2500, Toronto, Ontario M5H 1T1.

Investors in provinces outside of Ontario may serve a lawsuit against BridgeMe and/or one of its employees at the following addresses:

  - Alberta: BridgeMe Securities Inc., c/o Miller Thompson LLP, 2700, 10155 – 102 Street, Edmonton, Alberta T5J 4G8.
  - British Columbia: BridgeMe Securities Inc., c/o LML&S Services Inc., 1055 West George Street – 1500 Royal Centre, Vancouver, British Columbia V6E 4N7.
  - Manitoba: BridgeMe Securities Inc., c/o MLT Aikins LLP, 30th Floor – 360 Main Street, Winnipeg, Manitoba R3C 4G1.
  - Saskatchewan: BridgeMe Securities Inc., c/o MLT Aikins LLP, 1500 – 1874 Scarth Street, Regina, Saskatchewan S4P 4E9.

## Trusted Contact Person 

By providing us with a Trusted Contact Person, you are authorizing us to contact this person and to release confidential information about you or your account(s) to the Trusted Contact Person if, at any time during the use of the platform, we believe that financial exploitation, abuse or fraud has occurred, is occurring, may be attempted, or we have concerns about your decision-making capacity. Although you are not required to provide us with a Trusted Contact Person, by providing us with a Trusted Contact Person, you are enabling us to take steps we may feel are necessary to help protect your interests.

We will rely on the Trusted Contact Person information you provide us unless you advise us in writing that you have revoked your consent and provide us with the name and contact information of a new trusted contact person.

### What is a Trusted Contact Person?

A Trusted Contact Person is typically a reliable friend, family member or other individual of your choosing who you believe has your best interest in mind and who can speak to us about your health, mental capacity and your financial circumstances where we suspect financial exploitation, abuse or fraud, or we have concerns about your decision-making capacity.

A Trusted Contact Person is not granted power of attorney over your account(s). No instructions related to your account(s) will be accepted from the Trusted Contact Person unless your Trusted Contact Person also has a valid, continuing power of attorney or other authority that is established in writing to our satisfaction.

### When will we contact a Trusted Contact Person?

Because BridgeMe is an online exempt market dealer platform, there would be limited to no interactions between our dealing representatives and Investors. We will only contact the Trusted Contact Person if through those limited interactions financial exploitation, abuse or fraud is suspected or we have reason to believe that you have diminished or loss of mental capacity. It is up to our discretion whether or not the Trusted Contact Person is contacted.

_*If you choose to provide a Trusted Contact Person, please notify this person that you have provided their name and phone number for this purpose._

## Electronic Signatures and the Delivery of Documents

By using our funding platform, you have consented to the electronic delivery of any and all Documents (as per below) relating to the investment in Issuers via the platform in lieu of receiving such Documents by regular mail. For this purpose, "Documents" means any and all communications and documents relating to your profile and investments in Issuers, including, but not limited to, notices, regulatory and informational material, trade confirmations and account statements.

You may revoke your consent to electronic delivery by providing notice to [${EMAILS.COMPLIANCE}](mailto:${EMAILS.COMPLIANCE}) and further acknowledge that if you do so, paper copies of the Documents will then be received by regular mail.

BridgeMe applies electronic or digital signature technology **("E-signature")**, whether it is a computer-generated signature, an electronic copy of the Investor's true ink signature or otherwise. The Investor authorizes and instructs BridgeMe to accept and execute any and all such forms which are signed using an E-signature.  The Investor acknowledges and agrees that any forms signed using an E-signature via BridgeMe's platform (or sent by BridgeMe directly to the Investor) shall be treated by BridgeMe as valid and binding as the Investor's true ink signature. With forms provided by the Investor at any time using an E-signature, the Investor agrees to keep BridgeMe indemnified against any loss of any nature whatsoever arising to acting upon forms provided using an E-signature. The Investor acknowledges and agrees that BridgeMe may rely conclusively upon and shall incur no liability whatsoever including, without limitation, any losses (whether direct, indirect, consequential, in contract, tort, or otherwise) arising in respect of any action taken or omitted to be taken upon any instructions provided using an E-signature believed in good faith to be genuine or to be signed by the Investor. The foregoing shall not obligate BridgeMe to process instructions executed by E-signature. BridgeMe may decline to act on any E-signature instruction in its absolute discretion and intend to do so particularly in circumstances where BridgeMe is unable to verify whether an instruction has been provided by the Investor (or a party authorized to give instructions on behalf of the Investor).  If any instruction is submitted by the Investor and not acknowledged by BridgeMe, it is the Investor s obligation to contact BridgeMe to confirm receipt.

Last Updated: 2023-01-01`;

const RelationshipDisclosure = () => {
  return (
    <Container size="2" pb="8">
      <Markdown>{CONTENT}</Markdown>
    </Container>
  );
};

export default RelationshipDisclosure;

import { Container, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { t } from "i18next";

interface Column {
  header: string;
  subheader: string;
  text: string;
}

const Services = () => {
  const COLS: Column[] = [
    {
      header: t("exempt-market-dealer"),
      subheader:
        "Our regulatory capabilities allow us the full range of what is required to service our clients.",
      text: "BridgeMe upholds the highest standards when it comes to approaching regulatory requirements. We take pride in doing things the right way.",
    },
    {
      header: t("fund-raising"),
      subheader:
        "We leverage a wide range of exemptions and techniques available to us to help with your fund-raising activities.",
      text: "Our customized approach allows us to build the most optimized fund-raising strategy for your business based on your needs, specifications, and capabilities.",
    },
    {
      header: t("tech-focused"),
      subheader:
        "We are a tech driven company that doesn't shy away from our strengths.",
      text: "Our strong background in technology development allows us to approach modern problems with modern solutions.",
    },
  ];

  return (
    <>
      <div className="soft-header">
        <Container size="2">
          <Flex
            pt={PAD_LG.pv}
            pb={PAD_LG.pv}
            pl={PAD_LG.ph}
            pr={PAD_LG.ph}
            direction="column"
            gap={GAP.md}
          >
            <Heading align="center" size={FONT_SIZE.title}>
              Breaking barriers to the capital markets
            </Heading>

            <Text size={FONT_SIZE.text}>
              <div style={{ textAlign: "center" }}>
                We support our clients on a broad range of financial and
                strategic matters, including acting as Exempt Market Dealer, and
                Fund-Raising strategy & execution. No matter which services you
                need, we will build you the best team for the job.
              </div>
            </Text>
          </Flex>
        </Container>
      </div>

      <Container size="3">
        <Flex
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          direction="column"
          gap={GAP.lg}
        >
          <Flex justify="center">
            <img
              alt="Services"
              style={{
                width: "80vw",
              }}
              src="/images/services/collage.png"
            />
          </Flex>

          <Grid
            gap={GAP.md}
            pb={PAD_LG.pv}
            columns={{
              initial: "1",
              md: COLS.length.toString(),
            }}
          >
            {COLS.map((c, i) => (
              <Flex key={`service-${i}`} direction="column" gap={GAP.sm}>
                <Heading color="indigo" size={FONT_SIZE.header}>
                  {c.header}
                </Heading>
                <Heading weight="light" size={FONT_SIZE.subheader}>
                  {c.subheader}
                </Heading>
                <Text size={FONT_SIZE.text}>{c.text}</Text>
              </Flex>
            ))}
          </Grid>
        </Flex>
      </Container>
    </>
  );
};

export default Services;

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
} from "@radix-ui/themes";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { AppRoute } from "enums/router";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  return (
    <Container id="About" size="3">
      <Grid
        pt={PAD_LG.pv}
        pb={PAD_LG.pv}
        pl={PAD_LG.ph}
        pr={PAD_LG.ph}
        columns={{ initial: "1", md: "2" }}
        gap={GAP.md}
      >
        <Flex justify="center">
          <img
            alt="About Us"
            className="graphic-lg"
            src="/images/landing/about-us.png"
          />
        </Flex>
        <Flex direction="column" gap={GAP.md}>
          <Heading weight="light" color="indigo" size={FONT_SIZE.header}>
            {t("about-header")}
          </Heading>

          <Text size={FONT_SIZE.text}>{t("about-blurb-1")}</Text>

          <Text size={FONT_SIZE.text}>{t("about-blurb-2")}</Text>

          <Box>
            <Button onClick={() => navigate(`/${AppRoute.About}`)}>
              {t("about-us")}
            </Button>
          </Box>
        </Flex>
      </Grid>
    </Container>
  );
};

export default About;

import { Box, Container, Flex, Grid, Heading, Text } from "@radix-ui/themes";
import { SEPARATOR } from "components/landing";
import { FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import People from "./people";

const AboutUs = () => {
  return (
    <div id="AboutUs">
      <div className="soft-header">
        <Container size="2">
          <Flex
            pt={PAD_LG.pv}
            pb={PAD_LG.pv}
            pl={PAD_LG.ph}
            pr={PAD_LG.ph}
            direction="column"
            gap={GAP.sm}
          >
            <Heading align="center" size={FONT_SIZE.title}>
              With us, there's no need to compromise
            </Heading>

            <Heading align="center" weight="light" size={FONT_SIZE.subheader}>
              Situated in Toronto, Ontario, BridgeMe is Canada's leading tech
              enabled Exempt Market Dealer
            </Heading>

            <Text size={FONT_SIZE.text}>
              <div style={{ textAlign: "center" }}>
                We leverage our private market expertise with technological
                capabilities to service our clients' needs. Our forward thinking
                allows us to service the needs of modern businesses with modern
                solutions.
              </div>
            </Text>
          </Flex>
        </Container>
      </div>

      <Container size="3">
        <Grid
          pt={PAD_LG.pv}
          pb={PAD_LG.pv}
          pl={PAD_LG.ph}
          pr={PAD_LG.ph}
          columns={{ initial: "1", md: "2" }}
          gap={GAP.md}
        >
          <Flex justify="center">
            <img
              alt="About Us"
              className="graphic-lg"
              src="/images/about-us/collage.png"
            />
          </Flex>
          <Flex direction="column" gap={GAP.sm}>
            <Heading weight="light" color="indigo" size={FONT_SIZE.header}>
              Who we are
            </Heading>

            <Heading weight="light" size={FONT_SIZE.subheader}>
              Our mission is to deliver exceptional service to our clients
              leveraging all of the technology and regulatory expertise
              available to us.
            </Heading>

            <Text size={FONT_SIZE.text}>
              What does that mean? It means a better experience—and better
              outcomes—more efficient ways of doing things for everyone. For us,
              it's about doing the hands-on work we love, with clients we are
              personally committed to. For you, it's a handpicked team of
              experts that's with you from beginning to end, with a singular
              focus on making sure your objectives are met—and your expectations
              exceeded.
            </Text>
          </Flex>
        </Grid>

        {SEPARATOR}

        <Box pt={PAD_LG.pv} pb={PAD_LG.pv} pl={PAD_LG.ph} pr={PAD_LG.ph}>
          <People />
        </Box>
      </Container>
    </div>
  );
};

export default AboutUs;

import { Box, Button, Container, Flex, Heading, Text } from "@radix-ui/themes";
import { FLEX_DIR_MD, FONT_SIZE, GAP, PAD_LG } from "enums/radix-layout";
import { AppRoute } from "enums/router";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

interface Column {
  header: string;
  text: string;
  onLearnMore?: () => void;
}

const Services = () => {
  const navigate = useNavigate();

  const COLS: Column[] = [
    {
      header: t("emd-services"),
      text: t("emd-blurb"),
      onLearnMore: () => navigate(`/${AppRoute.Services}`),
    },
    {
      header: t("fund-raising"),
      text: t("fund-raising-blurb"),
      onLearnMore: () => navigate(`/${AppRoute.Services}`),
    },
    {
      header: t("tech-focused"),
      text: t("tech-focused-blurb"),
      onLearnMore: () => navigate(`/${AppRoute.Services}`),
    },
  ];

  return (
    <Container size="3">
      <Flex
        direction="column"
        pt={PAD_LG.pv}
        pb={PAD_LG.pv}
        pl={PAD_LG.ph}
        pr={PAD_LG.ph}
        gap={GAP.md}
      >
        <Heading align="center" weight="light" size={FONT_SIZE.header}>
          {t("services-header")}
        </Heading>
        <Flex justify="center" gap={GAP.md} direction={FLEX_DIR_MD}>
          {COLS.map((c, i) => (
            <Flex key={`service-${i}`} direction="column" gap={GAP.sm}>
              <Heading color="indigo" size={FONT_SIZE.title}>
                {c.header}
              </Heading>

              <Text size={FONT_SIZE.text}>{c.text}</Text>

              <Box>
                <Button
                  variant="outline"
                  disabled={!c.onLearnMore}
                  onClick={() => {
                    if (c.onLearnMore) {
                      c.onLearnMore();
                    }
                  }}
                >
                  {t("learn-more")}
                </Button>
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default Services;
